@import "../../../assets/sass/_variables.sass";

.checkout-cta{
    max-width: 184px;
    width: 100%;
    font-size: 14px;
    padding: 10px 15px;
    margin-bottom: 15px;
}
.checkout-cta.btn-outline-primary:hover{
    color: #fff !important;
}

#asideCheck01{
    z-index: 1;
}

.checkout-shadow{
    margin-bottom: 20px;
}

.accordion-button:not(.collapsed){
    background-color: unset ;
}

.accordion-body{
    
    form{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        flex-direction: column;
    }

    input{
        width: 100%;
        border: 1px solid rgba(150, 150, 150, 0.35);
        border-radius: 12px;
        padding: 10px;
    }

    button{
        background-color: #707070;
    }
}

.recommendations{
    margin-top: 48px;
    
    h3{
        padding: 0 10px;
        font-size: 36px;
        color: #707070 !important;
        margin-bottom: 36px;
    }
}

.accordion-button:not(.collapsed) {
    box-shadow: none;
}

.btn-activities,
.btn-compra {
    line-height: 1.2rem;
    border-radius: .25rem;
    height: 52px;
    padding: 0.35rem 20px;
}

.btn-activities:hover {
    color: #FFF !important;
}

.border-empty {
    border: 1px solid #FF6600;
}

@media (min-width: 768px) and (max-width: 991px) {
    .btn-activities, .btn-compra {
        padding: 0.35rem 5px !important;
        font-size: .75rem;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .btn-activities, .btn-compra {
        padding: 0.35rem 5px !important;
        font-size: .9rem;
    }
}

.card-cupom {
    & .accordion-header, .accordion-button {
        background-color: #E5E5E5;
    }
    & .accordion-button, .accordion-item:last-of-type, .accordion-item:first-of-type {
        border-radius: 0 !important;
        border: none !important;
    }
    & .accordion-button:focus {
        box-shadow: none;
    }
    & .accordion-body {
        padding: 1rem !important;
    }
    & input {
        border-radius: 0 !important;
        &:focus {
            border-color: #a5a5a5;
            outline: 0;
        }
    }
    & button.btn {
        background-color: #FF6600;
        border-radius: .25rem !important;
        margin-bottom: 0;
    }
    
    button.btn.checkout-remove-cupom {
        background-color: #FFFFFF;
    }
    button.btn.checkout-remove-cupom:hover {
        background-color: #FF6600;
        color: #FFF;
    }
}