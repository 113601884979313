.bg-footer {
    background-image: url("../../assets/img/bg-footer.webp");
    background-size: cover;
    background-repeat: no-repeat;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

    & .bg-partners-middle {
        border-right: 3px solid #FFF;
        padding: 0 2rem;
    }

    & .bg-refit {
        border-right: 3px solid #FFF;
        padding: 0 2rem;
    }

    & .bg-certicate-middle {
        padding: 0 1.5rem;
    }

    & h5 {
        font-size: 1.375rem;
        letter-spacing: 1px;
    }

    & p,
    small,
    a {
        font-size: 1rem;
        letter-spacing: 1px;
    }

    & .img-refit {
        width: 140px;
    }
}

.icons-footer {
    display: flex;
    justify-content: center;

    & img {
        width: 40px;
    }
}

.false {
    width: 70px;
    filter: invert(100%) sepia(51%) saturate(275%) hue-rotate(184deg) brightness(116%) contrast(100%);
}

@media (min-width: 1201px) and (max-width: 1400px) {
    .bg-footer {

        & p,
        small,
        a {
            font-size: .95rem;
        }
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .bg-footer {

        & h5 {
            font-size: 1rem;
        }

        & p,
        small,
        a {
            font-size: .75rem;
        }

        & h6 {
            font-size: .75rem;
        }
    }

    .icons-footer {
        & svg {
            font-size: 2rem !important;
        }
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .bg-footer {

        & .bg-partners-middle {
            padding: 0 1rem;

            & .col-5,
            .col-4,
            .col-3,
            .col-2 {
                width: 50%;
            }
        }

        & .bg-certicate-middle {

            & .col-5,
            .col-4,
            .col-3,
            .col-2 {
                width: 50%;
            }
        }

        & h5 {
            font-size: 1rem;
        }

        & p,
        small,
        a {
            font-size: .7rem;
        }

        & h6 {
            font-size: .7rem;
        }
    }

    .icons-footer {
        & svg {
            font-size: 2rem !important;
        }

        & .col-md-3 {
            width: 35%;
        }
    }

}

@media (max-width: 767px) {
    .bg-footer {
        background-image: url("../../assets/img/bg-footer-mobile.webp");

        & .bg-partners-middle {
            border-left: none;
            border-right: none;
            padding: 0 1rem;
        }

        & .bg-refit {
            border-right: none;
            padding: 0 1rem;
        }

        & .bg-certicate-middle {
            padding: 0 1rem;
        }

        & .bg-footer-menu {

            & p,
            a {
                font-size: 1rem !important;
            }
        }

        & p,
        small,
        a {
            font-size: .75rem;
            letter-spacing: 2px;
            line-height: 1rem;
        }

        & h6 {
            font-size: 1rem;
            letter-spacing: 2px;
            line-height: 1rem;
        }

        & .footer-lgpd {
            line-height: 1rem;
            margin-bottom: .5rem;

            & a {
                font-size: .75rem;
                letter-spacing: 2px;
            }
        }
    }
}

.bt-whatsApp {
    position: fixed;
    width: 60px;
    height: 60px; 
    right: 1rem; 
    bottom: 5.5rem;
    z-index: 100;

    & img {
        width: 60px;
    }
}
