@import "../../../assets/sass/_variables.sass";

#main-header {
  max-height: 200px;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;

}

@media (min-width: 576px) {
  .navbar.exceptional {
    padding: 1rem;
  }
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

.navbar-brand {
  padding: 0;
}

.navbar-brand img {
  width: 160px;
}

.nav-item-c2 {
  min-width: 75px;
}

@media (max-width: 820px) {
  .navbar-toggler .fa-bars {
    margin-right: 5px !important;
  }
}


@media (max-width: 480px) {
  .nav-item-c2 {
    min-width: 60px;
  }

  .navbar-toggler .fa-bars {
    margin-right: 10px !important;
  }

  .navbar.navbar-expand.navbar-light .dropdown-menu.my-account {
    right: -60px !important;
  }
}

@media (max-width: 360px) {
  .nav-item-c2 {
    min-width: 40px;
  }

  .navbar-toggler {
    padding: 8px !important;
  }

  .navbar-toggler svg,
  .nav-link svg {
    width: 1.125rem !important;
  }

  .nav-link img {
    width: 24px !important;
    height: 24px !important;
  }

  .navbar.navbar-expand.navbar-light .badge {
    font-size: 45% !important;
  }

  .navbar>.container,
  .navbar>.container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0px 6px;
  }
}

@media (max-width: 991.98px) {
  .navbar-brand img {
    width: clamp(100px, 15vw, 180px);
    margin: 5px 0;
  }

  .navbar.navbar-expand.navbar-light .carrinho .dropdown-menu {
    width: 350px !important;
  }

  .nav-item-c2 {
    height: 68px;

    & .nav-link {
      line-height: 34px;
    }
  }
}

.navbar.navbar-expand.navbar-light {
  font-size: 1.1rem;
  // padding: 0;
}

@media (max-width: 767.98px) {
  .navbar.navbar-expand.navbar-light .nav-link {
    padding: 8px !important;
  }
}

@media (max-width: 400px) {
  .navbar.navbar-expand.navbar-light {
    padding: 0px;
  }
}

.navbar.navbar-expand.navbar-light .nav-link:hover {
  background-color: #f4f4f4;
  transition: all linear 0.3s;
}

.navbar.navbar-expand.navbar-light .dropdown:hover>.dropdown-menu,
.navbar.navbar-expand.navbar-light .dropdown>.dropdown-menu.active {
  display: block;
  transition: all linear 0.5s;
}

.navbar.navbar-expand.navbar-light .dropdown-menu {
  border-radius: 0 !important;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  border-top: 0 !important;
  border: 0;
  background-clip: inherit;
  z-index: 10000000000;
  margin-top: 0 !important;
  background-color: #f4f4f4;
  right: 0 !important;
  left: inherit;
}

.dropdown-menu.my-account {
  width: 350px;
}

.navbar.navbar-expand.navbar-light .nav-link {
  transition: all linear 0.5s;
}

@media (max-width: 1199.98px) {
  .navbar.navbar-expand.navbar-light .nav-link {
    padding: 15px 5px !important;
    height: 100%;
  }
}

@media (min-width: 992px) {
  .navbar.navbar-expand.navbar-light {
    & .nav-item:nth-child(1) .nav-link {
      padding: 25px 15px !important;
      height: 100%;
    }

    & .nav-item:nth-child(3) .nav-link {
      padding: 21px 15px !important;
      height: 100%;
    }

    & .nav-item:nth-child(4) .nav-link {
      padding: 24px 15px !important;
      height: 100%;
    }
  }
}

.navbar.navbar-expand.navbar-light .carrinho .dropdown-menu {
  width: 290px;
}

@media (min-width: 992px) {
  .navbar.navbar-expand.navbar-light .carrinho .dropdown-menu {
    width: 400px;
  }
}

.navbar.navbar-expand.navbar-light .badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 70%;
  top: -15px;
  position: relative;
  z-index: 0;
}

.navbar-toggler {
  border: 0;
  outline: 0px !important;
}

.nav-pills-c2.nav-item {
  width: 10%;
}

.nav-pills-c2 .nav-link {
  padding: 1rem;
}

@media (max-width: 767.98px) {
  .nav-pills-c2 .nav-link {
    padding: 0.4rem !important;
  }
}

.nav-pills-c2 .nav-item.active {
  color: #ccc;
  background: #fe340c;
}

.nav-pills-c2 .nav-item.active .nav-link {
  color: #ccc;
}

.nav-pills-c2 .nav-item.active:first-child a {
  color: #ccc;
  background: #fe340c;
  border-radius: 10rem 0 0 10rem;
}

.nav-pills-c2 .nav-item.active:last-child a {
  color: #ccc;
  background: #fe340c;
  border-radius: 0 10rem 10rem 0;
}

@media (max-width: 767.98px) {
  .nav-pills-c2 .nav-item .nav-link h5 {
    font-size: 0.6rem;
  }
}

@media (max-width: 767.98px) {
  .nav-pills-c2 .nav-item .nav-link i {
    font-size: 1rem !important;
  }
}

@media (min-width: 576px) {
  .nav-pills-c2 .nav-item .nav-link i {
    font-size: 1.5rem !important;
  }
}

@media (min-width: 768px) {
  .nav-pills-c2 .nav-item .nav-link i {
    font-size: 2.5rem !important;
  }
}

.nav-pills-c2 .nav-item a:hover {
  background: #fe340c;
  color: #fff;
}

.nav-pills-c2 .nav-item:first-child a:hover {
  border-radius: 10rem 0 0 10rem;
}

.nav-pills-c2 .nav-item:last-child a:hover {
  border-radius: 0 10rem 10rem 0;
}

.navbar>.container,
.navbar>.container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar>.container-md {
  flex-wrap: nowrap;
}

i.fa-lg img {
  max-height: 25px;
}

.navbar-toggler:focus {
  box-shadow: none !important;
}

.dropdown-menu.px-4.py-3.rounded.languages button,
.dropdown-menu.px-4.py-3.rounded.my-account button {
  display: inline-flex;
  border: none;
  background-color: transparent;
  float: left;
}

.dropdown-menu.px-4.py-3.rounded.my-account button.login {
  display: block;
  border: none;
  background-color: $primary;
  float: none;
}

.dropdown-menu.my-account.logged-in.px-4.py-3.rounded {
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  width: 190px;
}

.dropdown-menu.px-4.py-3.rounded button img {
  pointer-events: none;
}

#menuSideBar {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;

  .menuOptions {
    width: 250px;
    display: flex;
    height: 100vh;
    background-color: #ff6600;
    justify-content: space-between;
  }
}


.hours-function {
  font-weight: 700;
  text-align: center;
  line-height: 1rem;

  & small {
    font-size: calc(12px + 0.05vw);
  }

  & div {
    padding: 2px 0px;
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .hours-function {
    line-height: 1rem;

    & small {
      font-size: calc(10px + 0.05vw);
    }
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .hours-function {
    line-height: .75rem;

    & small {
      font-size: calc(10px + 0.05vw);
    }
  }

}

@media (min-width: 768px) and (max-width: 991px) {
  .hours-function {
    line-height: .75rem;

    & small {
      font-size: calc(7.5px + 0.05vw);
    }
  }

}

@media (min-width: 576px) and (max-width: 767px) {
  .hours-function {
    padding: 0 0 7.5px;
    position: inherit;
    right: 0;
    line-height: 1.25;
    text-align: center;

    &.d-none.d-sm-block {
      display: none !important;
    }

    &.d-sm-none {
      display: block !important;
    }
  }
}

@media (max-width: 575px) {
  .hours-function {
    padding: 0 0 7.5px;
    position: inherit;
    right: 0;
    line-height: 1.05;
    text-align: center;

    & small {
      font-size: clamp(7px, 2.5vw, 10.5px);
      line-height: 1.5;
    }

    &.d-none.d-sm-block {
      display: none !important;
    }

    &.d-sm-none {
      display: block !important;
    }
  }
}

@media (max-width: 360px) {
  .hours-function {

    & small {
      line-height: 1.25;
    }
  }
}

small.text-white.content-hours {
  display: flex;
  flex-direction: column;
}


.alt-login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0.5rem 0;
}

.loggedIn-profile-pic {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background-position: center center;
  background-size: 120%;
  margin: auto;
}

.buy-tickets-cta {
  max-width: 273px;
  width: 100%;
  background-color: #fff;
  color: $primary;
  padding: 8px 55px;
  border-radius: 12px;
  border: none;
  margin-right: 35px;
}

@media (max-width: 767.98px) {
  .buy-tickets-cta {
    display: none;
  }

  #navbarDropdownCarrinho {
    & svg {
      vertical-align: middle;
    }
  }
}

.btnFacebook {
  color: #fff;
  background-color: #3b5998;
  width: 100%;
  height: 43px;
  //padding-right: 35px;
  font-size: 14px;

  i {
    margin-right: 30px;

  }
}

#navbarDropdownLang {
  & .language-selected {
    display: none;
  }
}

@media (min-width: 1200px) {
  #navbarDropdownLang {
    width: 150px;

    & .language-selected {
      color: white;
      font-size: 1rem;
      display: inline-block;
    }

    &:hover {
      & .language-selected {
        color: #000;
      }
    }
  }
}

#authLoginDropdown>div>div.d-flex.row>div:nth-child(3)>button>div,
#root>div>div.container>div>div>div>div.d-flex.row>div:nth-child(3)>button>div,
#left-tabs-example-tabpane-stepData>div>div>div>div>div.d-flex.row>div:nth-child(3)>button>div {
  border-radius: 20px !important;
}